import React from 'react';
import { WrapLarge } from '$components/Wraps';
import Heading1 from '$components/Heading1';
import FadeInSection from '$components/FadeInSection';
import Markdown from '$components/Markdown';
import { BlockContactHeroProps } from './types';
import Contacts from './Contacts';

const BlockContactHero: React.FC<BlockContactHeroProps> = ({ heading, column1, column2, column3 }) => (
  <div>
    <WrapLarge>
      <FadeInSection>
        <Heading1 primary>{heading}</Heading1>
      </FadeInSection>
    </WrapLarge>
    <Contacts>
      <FadeInSection>
        <address>
          <Markdown content={column1} />
        </address>
      </FadeInSection>

      <FadeInSection>
        <address>
          <Markdown content={column2} />
        </address>
      </FadeInSection>

      <FadeInSection>
        <address>
          <Markdown content={column3} />
        </address>
      </FadeInSection>
    </Contacts>
  </div>
);

export default BlockContactHero;
