import React from 'react';
import styled from 'styled-components';
import { WrapLarge } from '$components/Wraps';

const StyledContacts = styled(WrapLarge)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3.611111111vw 7.222222222vw;
  margin-bottom: 3.611111111vw;

  @media screen and (min-width: 978px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (min-width: 1440px) {
    grid-gap: 6.5rem 6.5rem;
  }
`;
const Contacts: React.FC = ({ children }) => <StyledContacts>{children}</StyledContacts>;

export default Contacts;
